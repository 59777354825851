<template>
    <div>
        <div class="row page-title-header">
            <div class="col-12">
                <div class="page-header">
                    <b-breadcrumb class="m-0">
                        <b-breadcrumb-item :to="{
                            name: 'DashboardHome',
                        }">
                            <i class="fa fa-home"></i>
                        </b-breadcrumb-item>

                        <b-breadcrumb-item :to="{
                            name: 'InvoiceList',
                        }">發票列表
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>發票明細</b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
            </div>
        </div>

        <b-card>
            <b-overlay :show="showLoading" rounded="sm">
                <div class="row mb-2 align-items-center">
                    <div class="col-12 col-xl-2">
                        <h4 class="font-weight-bold">發票明細</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <b-form-group :disabled="readonly">
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="會員名稱">
                                <b-form-input v-model="invoice.customer_name"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="發票號碼">
                                <b-form-input v-model="invoice.invoice_number" maxlength="8"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="發票狀態">
                                <b-form-select v-model="invoice.invoice_status" :options="invoiceStatusOptions">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="發票開立日期">
                                <b-form-input v-model="invoice.invoice_date"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="發票作廢日期">
                                <b-form-input v-model="invoice.invoice_cancelled_at"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="隨機碼">
                                <b-form-input v-model="invoice.random" maxlength="4"></b-form-input>
                            </b-form-group>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group :disabled="readonly">
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="金額">
                                <b-form-input v-model="invoice.total_price"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="年份">
                                <b-form-input v-model="invoice.invoice_year"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="期別">
                                <b-form-input v-model="invoice.invoice_period"></b-form-input>
                            </b-form-group>
                            <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                content-cols-lg="6" label="建檔日期">
                                <b-form-input v-model="invoice.created_at"></b-form-input>
                            </b-form-group>
                            <b-form-group :disabled="readonly">
                                <b-form-group label-cols="12" label-cols-lg="2" label-size="m" content-cols="12"
                                    content-cols-lg="6" label="發票種類">
                                    <b-form-select v-model="invoice.invoice_type" :options="invoiceTypesOptions">
                                    </b-form-select>
                                </b-form-group>
                            </b-form-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row mt-4 mb-2 align-items-center">
                    <div class="col-12 col-xl-2">
                        <h4 class="font-weight-bold">發票明細</h4>
                    </div>
                </div>
                <b-table striped hover :items="invoice.invoiceItems" :fields="fields">
                </b-table>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import invoiceApi from "../../../apis/invoice";
import { mapState } from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            showLoading: false,
            invoice: {
            },
            fields: [
                { key: "row_num", label: "序號" },
                { key: "name", label: "名稱", },
                { key: "unit_price", label: "單價", },
                { key: "quantity", label: "數量", },
                { key: "amount", label: "總價", },
                { key: "processed_memo", label: "處理說明", },
            ],
            invoiceTypesOptions: [
                { text: '電子發票', value: '0' },
                { text: '捐贈發票', value: '1' },
                { text: '二聯式發票', value: '2' },
                { text: '三聯式發票', value: '3' },
            ],
            invoiceStatusOptions: [
                { text: '已開立', value: 'active' },
                { text: '已作廢', value: 'cancel' },
            ]
        };
    },
    computed: {
        ...mapState("general", {
            organization: (state) => state.organization,
        }),
        readonly() {
            return this.$route.name === "InvoiceDetail"
        },
    },
    mounted() {
        this.fetchInvoice()
    },
    methods: {
        async fetchInvoice() {
            this.showLoading = true;

            try {
                const { data } = await invoiceApi.getInvoice(this.$route.params.invoiceId);
                this.invoice = data.data;
                this.invoice.invoice_date = this.formatDate(this.invoice.invoice_date)
                this.invoice.invoice_cancelled_at = this.formatDate(this.invoice.invoice_cancelled_at)
                this.invoice.created_at = this.formatDate(this.invoice.created_at);
                this.invoice.customer_name = data.data?.customer?.name;
            } catch (error) {
                console.error(error);
                alert("取得發票資料錯誤");
            }
            this.showLoading = false;
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format("YYYY-MM-DD HH:mm");
            }
        },
    },
};
</script>
