import https from "./https"
import store from "@/store"

const invoice = {
    getInvoices(params) {
      const organization = store.state.general.organization
      return https.get('admin/organizations/' + organization.id + '/invoices', {params})
    },
    getInvoice(id) {
      const organization = store.state.general.organization
      return https.get('admin/organizations/' + organization.id + '/invoices/' + id)
    },
  }
  
  export default invoice